<template>
  <div>
    <div style="padding: 10px">
      <el-tabs type="border-card">
        <el-tab-pane label="基础信息">
          <el-form ref="form" :model="goodsInfo" label-width="80px">
            <el-form-item label="商品SKU">
              <el-tag type="success">{{ code }}</el-tag>
            </el-form-item>

            <el-form-item label="产品(中文)">
              <el-input v-model="goodsInfo.item_name"></el-input>
            </el-form-item>

            <el-form-item label="产品(英文)">
              <el-input v-model="goodsInfo.item_en"></el-input>
            </el-form-item>

            <el-form-item label="海关编码">
              <el-input
                v-model="goodsInfo.hs_code"
                style="width: 30%"
              ></el-input>
            </el-form-item>

            <el-form-item label="产品类型">
              <el-input
                v-model="goodsInfo.gt_cls_no"
                style="width: 30%"
              ></el-input>
            </el-form-item>

            <el-form-item label="规格">
              <el-input-number
                v-model="goodsInfo.purchase_spec"
                :min="1"
                :max="999"
                label="规格"
              >
              </el-input-number>
            </el-form-item>

            <el-form-item label="箱规">
              <el-input-number
                v-model="goodsInfo.lot_spec"
                :min="1"
                :max="999"
                label="箱规"
              >
              </el-input-number>
            </el-form-item>

            <el-form-item label="配送价">
              <el-input
                v-model="goodsInfo.base_price"
                style="width: 30%"
              ></el-input>
            </el-form-item>

            <el-form-item label="零售价">
              <el-input
                v-model="goodsInfo.sale_price"
                style="width: 30%"
              ></el-input>
            </el-form-item>

            <el-form-item label="海鼎库存">
              <el-tag type="success">{{ goodsInfo.stock_num }}</el-tag>
            </el-form-item>

            <el-form-item label="库存占用">
              <el-tag type="success">购物车:{{ goodsInfo.carts_num }}</el-tag>
              &nbsp;&nbsp;&nbsp;
              <el-tag type="danger">订单:{{ goodsInfo.order_num }}</el-tag>
            </el-form-item>

            <el-form-item label="可用库存">
              <el-tag type="warning">{{ goodsInfo.us_num }}</el-tag>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmitGoodsInfo"
                >设置</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <!--2-->
        <el-tab-pane label="扩展信息">
          <el-tag type="success" style="margin: 10px 0">SKU: {{ code }}</el-tag>
          <div class="extendedDiv">
            <!-- 左边 -->
            <div class="extendedDiv_div">
              <p style="border-bottom: 1px solid #218da0; margin: 10px">
                中文：
              </p>
              <el-form ref="form" label-width="80px">
                <el-form-item label="材质" style="width: 100%">
                  <el-input v-model="ExInfo.material"></el-input>
                </el-form-item>

                <el-form-item label="重量" style="width: 100%">
                  <el-input v-model="ExInfo.weight"></el-input>
                </el-form-item>

                <el-form-item label="尺寸" style="width: 100%">
                  <el-input v-model="ExInfo.lwh"></el-input>
                </el-form-item>

                <el-form-item label="填充物" style="width: 100%">
                  <el-input v-model="ExInfo.stuffing"></el-input>
                </el-form-item>

                <el-form-item label="产品特点" style="width: 100%">
                  <el-input v-model="ExInfo.features"></el-input>
                </el-form-item>

                <el-form-item label="使用方法" style="width: 100%">
                  <el-input v-model="ExInfo.usage"></el-input>
                </el-form-item>

                <el-form-item label="注意事项" style="width: 100%">
                  <el-input v-model="ExInfo.notes"></el-input>
                </el-form-item>

                <el-form-item label="警告语" style="width: 100%">
                  <el-input v-model="ExInfo.caution"></el-input>
                </el-form-item>
              </el-form>
            </div>

            <!-- 右边 -->
            <div class="extendedDiv_div2">
              <p style="border-bottom: 1px solid #218da0; margin: 10px">
                英文：
              </p>
              <el-form ref="form" label-width="80px">
                <el-form-item label="材质" style="width: 100%">
                  <el-input v-model="ExInfo.material_en"></el-input>
                </el-form-item>

                <el-form-item label="重量" style="width: 100%">
                  <el-input v-model="ExInfo.weight_en"></el-input>
                </el-form-item>

                <el-form-item label="尺寸" style="width: 100%">
                  <el-input v-model="ExInfo.lwh_en"></el-input>
                </el-form-item>

                <el-form-item label="填充物" style="width: 100%">
                  <el-input v-model="ExInfo.stuffing_en"></el-input>
                </el-form-item>

                <el-form-item label="产品特点" style="width: 100%">
                  <el-input v-model="ExInfo.features_en"></el-input>
                </el-form-item>

                <el-form-item label="使用方法" style="width: 100%">
                  <el-input v-model="ExInfo.usage_en"></el-input>
                </el-form-item>

                <el-form-item label="注意事项" style="width: 100%">
                  <el-input v-model="ExInfo.notes_en"></el-input>
                </el-form-item>

                <el-form-item label="警告语" style="width: 100%">
                  <el-input v-model="ExInfo.caution_en"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div
            style="border: 1px solid #218da0;border-top: none;padding-top: 10px;"
          >
            <el-form ref="form" label-width="120px">

              <el-form-item label="电池信息" style="width: 50%">
                <el-input v-model="goodsInfo.xm_batteryinfo"></el-input>
              </el-form-item>

              <el-form-item label="包装LOG" style="width: 50%">
                <el-input v-model="goodsInfo.xm_logo"></el-input>
              </el-form-item>

              <el-form-item label="保质期" style="width: 50%">
                <el-input v-model="ExInfo.expiration"></el-input>
              </el-form-item>

              <el-form-item label="产品相关证书" style="width: 50%">
                <el-input v-model="ExInfo.credential"></el-input>
              </el-form-item>

              <el-form-item label="产品型号" style="width: 50%">
                <el-input v-model="ExInfo.model"></el-input>
              </el-form-item>

              <el-form-item label="包装形式" style="width: 50%">
                <el-input v-model="ExInfo.packaging"></el-input>
              </el-form-item>

              <el-form-item label="执行标准" style="width: 50%">
                <el-input v-model="ExInfo.standard"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="display: flex; justify-content: flex-end; padding-top: 10px"
          >
            <el-button type="primary" @click="onSubmitGoodsEx">设置</el-button>
          </div>
        </el-tab-pane>

        <!-- 3 -->
        <el-tab-pane label="关联产品">
          <div>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>相关联产品</span>
              </div>

              <div>
                <ul>
                  <li>
                    <el-input
                      placeholder="产品SKU"
                      v-model="setIsBuy"
                    ></el-input>
                  </li>
                  <li style="margin-top: 3px">
                    <el-button size="mini" @click="addCodeList">添加</el-button>
                    <!-- <el-button type="primary" size="mini">产品相互关联</el-button> -->
                  </li>
                </ul>
                <div style="margin-top: 5px">
                  <el-table
                    :data="goodsRelated"
                    height="250"
                    border
                    style="width: 100%"
                    l
                  >
                    <el-table-column
                      prop="date"
                      label="图片"
                      width="180"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <img
                          height="60"
                          :src="`http://hwimg.xmvogue.com/thumb/${scope.row.sku_list}.jpg?x-oss-process=style/440`"
                          class="mr-3"
                          alt="..."
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="sku_list"
                      label="产品SKU"
                      width="180"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      label="绑定购买"
                      width="260"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-switch
                          v-model="scope.row.is_buy"
                          inactive-text="不绑定"
                          :inactive-value="0"
                          active-text="绑定"
                          :active-value="1"
                          @change="changeUp($event, scope.row, 'new')"
                        >
                        </el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="delGoodsRelated(scope.row)"
                        >
                          移除
                        </el-button>
                        <!-- <el-button type="text"  size="mini">
													相互移除
												</el-button> -->
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-card>
          </div>
        </el-tab-pane>

        <!-- 4 -->
        <el-tab-pane label="禁配设置">
          <el-form label-width="120px">
            <el-form-item label="禁配地区">
              <el-select
                v-model="dryData.value"
                filterable
                placeholder="请选择"
                multiple
                style="width: 80%"
              >
                <el-option
                  v-for="item in dryData.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="备注">
              <el-input type="textarea" :rows="4" v-model="dryData.ban_remark"></el-input>
            </el-form-item>

            <!-- <el-form-item label="禁配国家CODE"  v-show="dryData.value.length">
							<div style="border: 1px solid #cfcfcf; padding:5px; width: 80%;">{{ dryData.value }}</div>
						</el-form-item> -->

            <el-form-item label="快速选择">
              <el-checkbox-group v-model="checkboxGroupSel" size="small">
                <el-checkbox
                  label="欧盟"
                  border
                  @change="(val) => changeCountry(val, '欧盟')"
                ></el-checkbox>
                <el-checkbox
                  label="伊斯兰教"
                  border
                  @change="(val) => changeCountry(val, '伊斯兰教')"
                ></el-checkbox>
                <el-checkbox
                  label="海湾"
                  border
                  @change="(val) => changeCountry(val, '海湾')"
                ></el-checkbox>
                <el-checkbox
                  label="东盟"
                  border
                  @change="(val) => changeCountry(val, '东盟')"
                ></el-checkbox>
                <el-checkbox
                  label="全部"
                  border
                  @change="(val) => changeCountry(val, '全部')"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmitBanArea">设置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <!-- 5 -->
        <el-tab-pane label="媒体设置">媒体设置</el-tab-pane>

        <!-- 6 -->
        <el-tab-pane label="小图">
          <el-form ref="form" label-width="80px">
            <div>
              <h2 style="color:red;">温馨提示：上传的小图会展示在前端的商品详情左侧位置，如下图所示：</h2>
              <el-image 
                style="width: 300px; height: 300px"
                :src="demoImg" 
                >
              </el-image>
            </div>
            <!-- 图片 -->
            <el-form-item label="产品图">
              <el-upload
                class="upload-demo"
                :action="uploadPostUrl"
                :headers="header"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="handleGoodsImgBeforeRemove"
                :file-list="uploadGoodsImgList"
                :on-success="SuccessGoodsImg"
                :on-exceed="limitGoodsImgNum"
                :multiple="false"
                :limit="GoodsImgLimit"
                list-type="picture"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="display: inline-block;margin-left: 20px;"
                >只能上传jpg/png/jpeg/gif文件，最多 <strong>5</strong> 张</div>
              </el-upload>
            </el-form-item>
            <!--  -->
            <el-form-item>
              <el-button type="primary" @click="onSubmitGoodsImg"
                >提交并保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        
      </el-tabs>
    </div>
  </div>
</template>

<script>
import demoImg from "@/assets/20221227162032.png";

export default {
  props: {
    code: {
      type: String,
      default: "请输入你的参数",
    },
  },

  data() {
    return {
      checkboxGroupSel: [],
      setIsBuy: "",
      goodsRelated: [],
      props: {
        multiple: true,
      },
      //禁配数据
      dryData: {
        value: [],
        options: [],
        ban_remark:"",
      },
      // 图片上传POST地址
      header: {
        Authorization: ''
      },
      uploadPostUrl: '',
      uploadGoodsImgList: [], // 商品图片
      GoodsImgLimit: 5, // 商品图片上传限制数量
      demoImg:demoImg,
      //基本详情
      goodsInfo: {},
      //扩展详情
      ExInfo: {},
      //媒体列表
      media: {},
      countryArr: {
        欧盟: [
          "DE",
          "FR",
          "IT",
          "ES",
          "PT",
          "NL",
          "BE",
          "LU",
          "IE",
          "AT",
          "HU",
          "PL",
          "CZ",
          "SK",
          "SI",
          "HR",
          "DK",
          "SE",
          "FI",
          "CY",
          "MT",
          "GR",
          "RO",
          "BG",
          "EE",
          "LV",
          "LT",
          "GB",
          "REU",
        ],
        伊斯兰教: [
          "MY",
          "BN",
          "ID",
          "BD",
          "MV",
          "PK",
          "AFG",
          "KZ",
          "UZ",
          "TJ",
          "KGZ",
          "TKM",
          "AZ",
          "IR",
          "IQ",
          "KW",
          "SY",
          "TR",
          "JO",
          "BL",
          "SA",
          "BH",
          "QA",
          "ARE",
          "OM",
          "YE",
          "AF",
          "LBY",
          "TUN",
          "DZ",
          "MAR",
          "SDN",
          "MRT",
          "SO",
          "DJI",
          "COM",
          "SEN",
          "GMB",
          "GIN",
          "SLE",
          "MLI",
          "NER",
          "NGA",
          "TCD",
          "ALB",
          "BIH",
          "XKX",
        ],
        海湾: ["SA", "KW", "BH", "QA", "ARE", "OM", "YE"],
        东盟: ["VN", "LA", "KH", "TH", "MM", "PH", "MY", "SG", "BN", "ID"],
      },
    };
  },
  watch: {
    code() {
      this.getGoodsInfo();
      this.getCountryList();
      this.getGoodsRelated();
    },
  },
  created() {
    this.getGoodsInfo();
    this.getCountryList();
    this.getGoodsRelated();
    this.setUploadUrl(); //设置图片上传地址
    this.setHeadersToken(); //设置图片上传token
  },
  methods: {
    //=========== 快速选中国家效果 begin
    changeCountry(val, name) {
      if (name === "全部") {
        if (val) {
          //选中状态
          for (let key in this.dryData.options) {
            let code = this.dryData.options[key].value;
            //不存在 则 增加
            if (!this.dryData.value.includes(code)) {
              this.dryData.value.push(code);
            }
          }
        } else {
          this.dryData.value = [];
        }
      }
      //其他特殊地区
      this.checkboxGroupSelFun(val, name);
    },
    //获取国家在选中keyId，以方便做删除
    getKeyId(str, forArr) {
      let keyId = 0;
      if (forArr.length > 0) {
        for (let key in forArr) {
          if (forArr[key] == str) {
            keyId = key;
          }
        }
      }
      return keyId;
    },
    //快速选择
    checkboxGroupSelFun(ok, name) {
      if (ok) {
        //选中状态
        for (let key in this.countryArr[name]) {
          let code = this.countryArr[name][key];
          //不存在 则 增加
          if (!this.dryData.value.includes(code)) {
            this.dryData.value.push(code);
          }
        }
      } else {
        //非选中状态
        for (let key in this.countryArr[name]) {
          let code = this.countryArr[name][key];
          //存在 则 删除
          if (this.dryData.value.includes(code)) {
            let delKey = this.getKeyId(code, this.dryData.value);
            this.dryData.value.splice(delKey, 1);
          }
        }
      }
    },
    //=========== 快速选中国家效果 end

    //删除关联产品
    delGoodsRelated(row) {
      let id = row.id;
      this.$axios.delete(`/a1/goodsRelate/${id}`).then((res) => {
        this.$message.success("删除成功");
        this.getGoodsRelated();
      });
    },
    //添加商品关联
    addCodeList() {
      let psotData = {
        sku_base: this.code + "",
        sku_list: this.setIsBuy + "",
        is_buy: "0",
      };
      this.$axios.post(`/a1/goodsRelate`, psotData).then((res) => {
        this.$message.success("添加成功");
        this.getGoodsRelated();
      });
    },
    //是否绑定购买选择器
    changeUp: function (callback, row, eventType) {
      let putData = {
        is_buy: row.is_buy + "",
      };
      let id = row.id;
      this.$axios.put(`/a1/goodsRelate/${id}`, putData).then((res) => {
        this.$message.success("修改成功");
      });
    },
    //获取关联产品
    getGoodsRelated() {
      let sku = this.code;
      this.$axios.get(`/a1/goodsRelate/${sku}`).then((res) => {
        this.goodsRelated = res.data.data;
      });
    },
    //提交禁配地区
    onSubmitBanArea() {
      let updateId = this.goodsInfo.id;
      let banStr = this.dryData.value.join(",");

      let postData = {
        ban_area: banStr + "",
        change_ban_area:true,
        ban_remark:this.dryData.ban_remark
      };
      if (updateId > 0) {
        this.$axios.put(`/a1/goods/${updateId}`, postData).then(() => {
          this.$message.success("修改成功");
          this.getGoodsInfo();
        });
      }
    },
    //获取国家
    getCountryList() {
      this.dryData.options = [];
      this.$axios.get(`/a1/country`).then((res) => {
        let options = [];
        let resData = res.data.data;
        //this.$message.success(resData[1].code,)
        for (let i = 0; i < resData.length; i++) {
          let newData = {
            value: resData[i].code,
            label: resData[i].name,
          };
          options.push(newData);
        }
        this.dryData.options = options;
      });
    },

    //选择禁配地区
    ChangeArea(value) {},
    //获取商品详情
    getGoodsInfo() {
      let codeStr = this.code;
      if (codeStr != "") {
        this.$axios.get(`/a1/goods/${codeStr}`).then((res) => {
          this.goodsInfo = res.data.data.GoodsInfo;
          this.ExInfo = res.data.data.GoodsExtended;
          var goodsImgList = res.data.data.GoodsSmallImage
          for (const key in goodsImgList) {
            this.uploadGoodsImgList.push({name:goodsImgList[key].file_name,url:goodsImgList[key].url})
          }
          console.log(res.data.data.GoodsSmallImage)
          //this.dryData.value = this.goodsInfo.ban_area

          //备注
          this.dryData.ban_remark = this.goodsInfo.ban_remark
          //禁配地区
          let banArea = this.goodsInfo.ban_area;
          if (banArea != "") {
            let banAreaArr = banArea.split(",");
            this.dryData.value = banAreaArr;
          } else {
            let banAreaArr = [];
            this.dryData.value = banAreaArr;
          }
        });
      }
    },
    //提交基本信息
    onSubmitGoodsInfo() {
      let updateId = this.goodsInfo.id;
      let postData = {
        item_name: this.goodsInfo.item_name,
        item_en: this.goodsInfo.item_en,
        hs_code: this.goodsInfo.hs_code + "",
        gt_cls_no: this.goodsInfo.gt_cls_no + "",
        purchase_spec: this.goodsInfo.purchase_spec + "",
        lot_spec: this.goodsInfo.lot_spec + "",
        base_price: this.goodsInfo.base_price + "",
        sale_price: this.goodsInfo.sale_price + "",
      };
      if (updateId > 0) {
        this.$axios.put(`/a1/goods/${updateId}`, postData).then((res) => {
          this.$message.success("修改成功");
          this.getGoodsInfo();
        });
      }
    },
    //提交商品扩展信息
    onSubmitGoodsEx() {
      let updateId = this.goodsInfo.id;
      //处理提交信息 begin
      let notJoin = ["updated_at", "item_no"];
      let postData = {};
      for (let key in this.ExInfo) {
        if (!notJoin.includes(key)) {
          if (this.ExInfo[key] != "") {
            postData[key] = this.ExInfo[key];
          }
        }
      }
      //处理提交信息 end
      if (updateId > 0) {
        this.$axios.put(`/a1/goods/${updateId}`, postData).then((res) => {
          this.$message.success("修改成功");
          this.getGoodsInfo();
        });
      } else {
        this.$message.error("ID没有获取到");
      }

      // let postData = {
      // 	stuffing: this.ExInfo.stuffing + "",
      // 	features: this.ExInfo.features + "",
      // 	usage: this.ExInfo.usage + "",
      // 	notes: this.ExInfo.notes + "",
      // 	caution: this.ExInfo.caution + "",
      // 	expiration: this.ExInfo.expiration + "",
      // 	credential: this.ExInfo.credential + "",
      // 	model: this.ExInfo.model + "",
      // 	packaging: this.ExInfo.packaging + "",
      // };

      // if (updateId > 0) {
      // 	this.$axios.put(`/a1/goods/${updateId}`, postData).then((res) => {
      // 		this.$message.success("修改成功");
      // 		this.getGoodsInfo();
      // 	});
      // }
    },

    /***********************************图片处理**************************** */
    // 提交上传的图片
    onSubmitGoodsImg(){
      var postData = {
        id:parseInt(this.goodsInfo.id),
        small_img_arr:[]
      }
      for (const key in this.uploadGoodsImgList) {
        postData.small_img_arr.push({goods_id:parseInt(this.goodsInfo.id),url:this.uploadGoodsImgList[key].url,item_no:this.code,file_name:this.uploadGoodsImgList[key].name})
      } 

      this.$axios.post(`/a1/goods/updateSmallImages`, postData).then((res) => {
        if (res.data.error === 0) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          })
        } else {
          this.$message({
            type: 'info',
            message: '提交失败！请重试'
          })
        }
      })

      console.log(postData)

    },
    // 商品图片上传超出限制数量
    limitGoodsImgNum() {
      if (this.uploadGoodsImgList.length >= this.GoodsImgLimit) {
        this.$message.error('上传超过数量，请先移除图片！')
      }
    },
    // 设置token
    setHeadersToken(){
      var token = window.sessionStorage.getItem("token")
      this.header.Authorization= JSON.parse(token)
    },
    // 设置上传图片地址
    setUploadUrl() {
      this.uploadPostUrl=this.$root.DownUrl + "/a1/upload/file";
    },
    // 预览图片
    handlePreview(file) {
      // console.log(file)
    },
    // 移除图片
    handleRemove(file, fileList) {
      // console.log(file)
    },
        /**
     * 删除商品图片之前确认
     */
     handleGoodsImgBeforeRemove(file, fileList) {
      const bv = false // 阻止直接移除，采用手动移除
      if (file.url.indexOf('/test/') > -1) {
        var delArr = []
        for (let index = 0; index < this.uploadGoodsImgList.length; index++) {
          if (this.uploadGoodsImgList[index].url === file.url) {
            this.uploadGoodsImgList.splice(index, 1) // 移除展示的图片
            delArr.push({ imgSrc: file.url })
          }
        }
        if (delArr.length > 0) {
          this.handleDeleteTestImg(delArr) // 删除test路径的图片
        }
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        // 移除展示的数组
          for (let index = 0; index < this.uploadGoodsImgList.length; index++) {
            if (this.uploadGoodsImgList[index].url === file.url) {
              this.uploadGoodsImgList.splice(index, 1) // 移除展示的图片
            }
          }
          // 修改数据
          this.handleDeleteImg({ url:file.url })
          // 移除表单的数据
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })
      }
      // 阻止删除
      return bv
    },

        /**
     * 删除图片
     */
     handleDeleteImg(postData) {
      let updateId = this.goodsInfo.id;//商品ID
      this.$axios.put(`/a1/goods/deleteImg/${updateId}`, postData).then((res) => {
        if (res.data.error === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        }
      })
    },

    /* 删除未提交表单的图片 */
    handleDeleteTestImg(imgArr) {
      this.$axios.put(`/a1/goods/deleteTestImg`, {img_arr: imgArr}).then((res) => {
        if ( res.data.error=== 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        }
      })
    },

    // 上传商品图片成功回调
    SuccessGoodsImg(response, file, fileList) {
      console.log(file)
      if (file.response.error === 0) {
          this.uploadGoodsImgList.push({ name: file.name, url: file.response.data })
        }
    },

    /**********************************图片处理******************************* */
  },
};
</script>

<style lang="scss" scoped>
.extendedDiv {
  display: flex;

  div {
    width: 50%;
  }

  &_div {
    border: 1px solid #218da0;
  }

  &_div2 {
    border: 1px solid #218da0;
    border-left: none;
  }
}
</style>
