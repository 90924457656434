<template>
  <div>
    <div style="display: flex; align-items: center">
      <!-- 左边 放的按钮 -->
      <slot name="left"></slot>

      <div
        style="display: flex; align-items: center; margin-left: auto"
        v-show="showSetDiv == 0"
      >
        <slot name="right">
          <el-input
            v-model="keyword"
            @change="$emit('search', keyword)"
            size="mini"
            :placeholder="placeholder"
            style="margin-right: 10px"
          ></el-input>
          <!-- <el-button type="warning" size="mini" style="margin-left: 2px" @click="$emit('search', keyword)">搜索
					</el-button> -->
          &nbsp;
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="showSetDiv = 1"
            >更多搜索
          </el-button>
        </slot>
      </div>
    </div>

    <div v-show="showSetDiv == 1">
      <el-card class="box-card bg-light mt-2">
        <div slot="header" class="clearfix" style="margin: -15px">
          <span style="font-size: 12px">更多搜索</span>
          <el-button
            style="float: right; padding: 3px 0; font-size: 12px"
            type="text"
            @click="showSetDiv = 0"
          >
            收起</el-button>
        </div>
        <div class="text item">
          <slot name="form"></slot>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "请输入你的参数",
    },
  },
  data() {
    return {
      //导出
      exportData: {
        checkList: [],
      },
      keyword: "",
      showSetDiv: 0,
      exportFrom: {
        field: "",
      },
    };
  },

  methods: {
    closeSearch() {
      this.showSetDiv = 0;
    },
  },
};
</script>

<style></style>
